import React, { FC } from 'react'
import { StoreProvider } from '../store/context'
import { makeStyles, ThemeProvider } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import { useSelector } from 'react-redux'

import Footer from './footer'
import themes from '../theme'

import { RootState } from '../redux/store'
import './index.css'
import { Helmet } from 'react-helmet'
import langs from '../langs'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // maxWidth: '970px',
    alignItems: 'center',
    margin: '0 auto',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    width: '100%',
    // display: 'flex',
    // alignItems: 'center',
    // flexDirection: 'column',
  },
}))

const LayoutComponent: FC = ({ children }) => {
  const classes = useStyles()

  return (
    <>
      <div style={{ zIndex: 100 }} className={classes.root}>
        <main className={classes.main}>{children}</main>
        <Footer />
      </div>
    </>
  )
}

const Layout: FC = ({ children }) => {
  const { theme } = useSelector((state: RootState) => state.app)

  return (
    <ThemeProvider theme={themes[theme]}>
      <StoreProvider>
        <LayoutComponent>{children}</LayoutComponent>
        {/* <Helmet
          script={[
            {
              type: 'text/javascript',
              innerHTML: `!function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${langs.facebookPixelId}');
          fbq('track', 'PageView');`,
            },
          ]}
        /> */}
      </StoreProvider>
    </ThemeProvider>
  )
}

export default Layout
