import deepMerge from 'deepmerge'
import { deepOrange, green } from '@material-ui/core/colors'
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeOptions,
  Theme,
} from '@material-ui/core/styles'

const makeTheme = (variant: ThemeOptions): Theme => {
  const common = {}

  const theme = createMuiTheme(deepMerge(common, variant))
  return responsiveFontSizes(theme)
}

const myGreen: any = {
  100: '#75D000',
  200: '#75D000',
  300: '#75D000',
  400: '#75D000',
  500: '#75D000',
  600: '#75D000',
  700: '#75D000',
  800: '#75D000',
  900: '#75D000',
  A100: '#75D000',
  A200: '#75D000',
  A400: '#75D000',
  A700: '#75D000',
}

const light: ThemeOptions = {
  palette: {
    type: 'light',
    primary: myGreen,
    secondary: myGreen,
  },

  typography: {
    fontFamily: ['Inter'],
  },

  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitFontSmoothing: 'auto',
        },
        body: {
          padding: '0 0px',
        },
      },
    },

    MuiTypography: {
      p: {
        textAlign: 'justyfy',
      },
      h1: {
        marginBottom: '30px',
        textAlign: '',
        fontFamily: `'Inter', sans-serif`,
      },
      h4: {
        marginBottom: '50px',
        fontWeight: '700',
        maxWidth: '700px',
        fontSize: '18px',
        fontFamily: `'Inter', sans-serif`,
      },
      h2: {
        textAlign: '',
        fontWeight: '700',
        fontFamily: `'Inter', sans-serif`,
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',

        '&.wAuto': {
          width: 'auto',
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '16px',
        letterSpacing: '-2%',
        fontWeight: '500',
      },
    },
    MuiFilledInput: {
      root: { backgroundColor: 'none' },
      input: { border: '1px solid #ccc', borderRadius: '5px!important' },
      underline: {
        '&:before': {
          borderBottom: 'none',
        },
      },
    },
    MuiInputBase: {
      input: {
        fontSize: '16px',
        letterSpacing: '-2%',
        fontWeight: '500',
      },
    },
    MuiSelect: {
      selectMenu: {
        height: '',
      },
    },
    MuiButton: {
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      containedSizeLarge: {
        width: '100%',
      },

      root: {
        borderRadius: '5px',
        padding: '20px 40px !important',
        color: '#fff',
        boxShadow: 'none',
      },

      label: {
        color: '#fff',
        textTransform: 'none',
        textShadow: '1px 1px  #2D303440 !important',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '700',

        letterSpacing: '-0.02em',
      },
    },
    // @ts-ignore
    MuiAlert: { root: { margin: '20px 0px' } },
  },
}

const dark: ThemeOptions = {
  palette: {
    type: 'dark',
  },
}

const themes = {
  light: makeTheme(light),
  dark: makeTheme(dark),
}

export default themes
