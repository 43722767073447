import React, { FC } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { makeStyles } from '@material-ui/styles'
import { Container, Typography, Theme, Link, Box } from '@material-ui/core'
import langs, { __ } from '../langs'

const Footer: FC = () => {
  return (
    <footer>
      <div className={'section'}>
        <div className={'sectionInner'}>
          <p>© 2021-2024 Blink-cart.com</p>
          <div className="links">
            <GatsbyLink to={'/contact'}> {langs.contact.title}</GatsbyLink>
            <GatsbyLink to={'/terms'}> {langs.terms.title}</GatsbyLink>

            {/* <GatsbyLink
              to={'/blog/strategic-insights-website-speed-analysis-tools'}
            >
              Website Speed Tools
            </GatsbyLink>

            <GatsbyLink to={'/blog/maximize-prestashop-conversions'}>
              Maximize PrestaShop Conversions
            </GatsbyLink> */}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
