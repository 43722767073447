import React, { createContext, useContext, useReducer } from 'react'
import { Dispatch } from 'react'

import langs from '../langs'

export type ValidationRule = (val: unknown) => boolean

export type FormField = {
  onChange: ActionTypeTypes
  type: string
  valid: boolean
  value: string
  label: string
  rules: Array<ValidationRule>
  options?: Array<any>
}

type StoreTypes = {
  shippingCountry: FormField
  apiCustomerKey: FormField
  apiCustomerSecret: FormField
  apiToken: FormField
  apiTokenSecret: FormField
  emailCode: FormField
  apiKey: FormField
  email: FormField
  url: FormField
  software: FormField
}

export enum ActionTypeTypes {
  UPDATE_APIKEY,
  UPDATE_EMAIL,
  UPDATE_EMAIL_CODE,
  UPDATE_URL,
  UPDATE_SOFTWARE,
  UPDATE_SHIPPING_COUNTRY,
  UPDATE_API_CUSTOMER_KEY,
  UPDATE_API_CUSTOMER_SECRRET,
  UPDATE_API_TOKEN,
  UPDATE_API_TOKEN_SECRET,
}

export type ActionType = {
  type: ActionTypeTypes
  payload: any
}

type StoreTypesWithDispatch = {
  state: StoreTypes
  dispatch?: Dispatch<ActionType>
}
const validationRules = {
  required: (val: unknown) => (val ? true : false),
}

const storeDefault: StoreTypes = {
  shippingCountry: {
    onChange: ActionTypeTypes.UPDATE_SHIPPING_COUNTRY,
    type: 'select',
    valid: false,
    value: '',
    label: langs.common.lable_country,
    options: [
      { name: 'United States', value: 'shopify' },
      { name: 'Great Britain', value: 'shopify' },
      { name: 'Germany', value: 'magento2' },
      { name: 'Spain', value: 'wordpress' },
      { name: 'France', value: 'wordpress' },
      { name: 'Netherlands', value: 'wordpress' },
      { name: 'Australia', value: 'wordpress' },
      { name: 'Canada', value: 'wordpress' },
      { name: 'Poland', value: 'shopify' },
      { name: 'Italy', value: 'shopify' },
      { name: 'India', value: 'shopify' },
    ],

    rules: [validationRules.required],
  },
  apiCustomerKey: {
    onChange: ActionTypeTypes.UPDATE_API_CUSTOMER_KEY,
    type: 'text',
    valid: false,
    value: '',
    label: 'Customer Key',
    rules: [validationRules.required],
  },
  apiCustomerSecret: {
    onChange: ActionTypeTypes.UPDATE_API_CUSTOMER_SECRRET,
    type: 'text',
    valid: false,
    value: '',
    label: 'Customer Seceret',
    rules: [validationRules.required],
  },
  apiToken: {
    onChange: ActionTypeTypes.UPDATE_API_TOKEN,
    type: 'text',
    valid: false,
    value: '',
    label: 'Access Token',
    rules: [validationRules.required],
  },
  apiTokenSecret: {
    onChange: ActionTypeTypes.UPDATE_API_TOKEN_SECRET,
    type: 'text',
    valid: false,
    value: '',
    label: 'Token Secret',
    rules: [validationRules.required],
  },

  emailCode: {
    onChange: ActionTypeTypes.UPDATE_EMAIL_CODE,
    type: 'text',
    valid: false,
    value: '',
    label: 'Code',
    rules: [validationRules.required],
  },

  apiKey: {
    onChange: ActionTypeTypes.UPDATE_APIKEY,
    type: 'text',
    valid: false,
    value: '',
    label: langs.common.label_email,
    rules: [validationRules.required],
  },

  email: {
    onChange: ActionTypeTypes.UPDATE_EMAIL,
    type: 'text',
    valid: false,
    value: '',
    label: 'E-mail',
    rules: [validationRules.required],
  },
  url: {
    onChange: ActionTypeTypes.UPDATE_URL,
    type: 'text',
    valid: false,
    value: 'https://',
    label: langs.common.label_url,
    rules: [validationRules.required],
  },
  software: {
    onChange: ActionTypeTypes.UPDATE_SOFTWARE,
    type: 'select',
    valid: false,
    value: '',
    label: langs.common.label_software,
    options: [
      { name: 'Shopify', value: 'shopify' },
      { name: 'Magento 2.*', value: 'magento2' },
      { name: 'Prestashop 1.*', value: 'prestashop' },
      { name: 'WooCommerce 5.*', value: 'woocommerce' },
    ],
    rules: [validationRules.required],
  },
}

export const Store = createContext<StoreTypesWithDispatch>({
  state: storeDefault,
})

const reducer = (state: StoreTypes, action: ActionType): StoreTypes => {
  switch (action.type) {
    case ActionTypeTypes.UPDATE_SHIPPING_COUNTRY:
      return {
        ...state,
        shippingCountry: { ...state.shippingCountry, value: action.payload },
      }

    case ActionTypeTypes.UPDATE_API_CUSTOMER_KEY:
      return {
        ...state,
        apiCustomerKey: { ...state.apiCustomerKey, value: action.payload },
      }

    case ActionTypeTypes.UPDATE_API_CUSTOMER_SECRRET:
      return {
        ...state,
        apiCustomerSecret: {
          ...state.apiCustomerSecret,
          value: action.payload,
        },
      }

    case ActionTypeTypes.UPDATE_API_TOKEN_SECRET:
      return {
        ...state,
        apiTokenSecret: { ...state.apiTokenSecret, value: action.payload },
      }

    case ActionTypeTypes.UPDATE_API_TOKEN:
      return {
        ...state,
        apiToken: { ...state.apiToken, value: action.payload },
      }

    case ActionTypeTypes.UPDATE_SOFTWARE:
      return {
        ...state,
        software: { ...state.software, value: action.payload },
      }

    case ActionTypeTypes.UPDATE_URL:
      return { ...state, url: { ...state.url, value: action.payload } }

    case ActionTypeTypes.UPDATE_APIKEY:
      return { ...state, apiKey: { ...state.apiKey, value: action.payload } }

    case ActionTypeTypes.UPDATE_EMAIL_CODE:
      return {
        ...state,
        emailCode: { ...state.emailCode, value: action.payload },
      }

    case ActionTypeTypes.UPDATE_EMAIL:
      return { ...state, email: { ...state.email, value: action.payload } }

    default:
      throw 'Unknown action type.'
  }
}

export function StoreProvider({ children }: any): JSX.Element {
  const [state, dispatch] = useReducer(reducer, storeDefault)

  return <Store.Provider value={{ state, dispatch }}>{children}</Store.Provider>
}

export const useFormData = () => useContext(Store)

export default Store
